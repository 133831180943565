@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
:root {
  --primary-color: #382474;
}
.primary-color {
  color: var(--primary-color) !important;
}
.primary-bg {
  background-color: var(--primary-color) !important;
}
.justify {
  text-align: justify;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* overflow-x: hidden; */
}
* {
  color: white !important;
  font-family: "Montserrat", sans-serif;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.bg-image-top {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100%;
  min-height: auto;
  display: flex;
  flex-direction: column;
  transition: background-image 1s ease-in-out;
  /* background-blend-mode: overlay; */
  object-fit: cover;
  padding-top: 75px;
  /* z-index: -2; */
  position: relative;
}

.people-power-div {
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100%;
  min-height: auto;
  border-radius: 40px !important;
  box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.5);
}

.people-power-div::before {
  border-radius: 40px !important;
  content: "";
  background-color: #8413d4ac; /* Change the color and opacity as needed */
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.cookies {
  min-height: 100vh !important;
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100%;
  min-height: auto;
  border-radius: 40px !important;
  background-blend-mode: overlay;
  box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.5) !important;
}

.cookies::before {
  border-radius: 40px !important;
  content: "";
  background-color: #8413d421; /* Change the color and opacity as needed */
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}
.cookie-heading{
  z-index: 999;
  position: relative;
  color: #3c2697 !important;
}
.cookie-content>*{
color: var(--primary-color) !important;
text-align: justify;
}
.cookie-content>*>*>*{
color: var(--primary-color) !important;

}
.cookie-content>*>*{
color: var(--primary-color) !important;

}
.legal-page-nav
{
  color: var(--primary-color) !important;

}
.people-power-div-content {
  z-index: 99;
  position: relative;
}
.bg-image-top-container {
  margin-top: -50px !important;
  margin-bottom: 50px !important;
}
.people-div-3 {
  margin-top: -120px;
}
.people-div-6 {
  margin-top: -120px;
}
.people-div-7 {
  margin-top: -120px !important;
}
.col-div-10 {
  margin-top: -320px !important;
}
@media only screen and (max-width: 786px) {
  .testimonial-quote {
    display: none !important;
  }
  .people-div-2 {
    width: 100% !important;
    margin-top: 15px;
  }
  .people-div-3 {
    margin-top: 15px !important;
    height: 400px;
  }
  .people-div-6 {
    margin-top: 15px !important;
    height: 400px;
  }
  .people-div-7 {
    margin-top: 15px !important;
  }
  .col-div-10 {
    margin-top: 15px !important;
  }
  .bg-image-top-container {
    margin-bottom: 150px !important;
    margin-top: 0px !important;
  }
  .center-div {
    width: 90% !important;
  }
  .about-us-img-wrapper {
    margin-top: -40px !important;
  }
  .market-research,
  .compititor-analysis,
  .customer-satisfiaction {
    margin-top: 10px !important;
  }
  .customer-satisfiaction {
    padding-left: 7px !important;
  }
}
.bg-image {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: auto;
  transition: background-image 1s ease-in-out;
  background-blend-mode: overlay;
  object-fit: cover;
}
.navbar {
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px); /* For Safari */
}
.dropdown-blured {
  border: none;
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
  background-color: rgba(255, 255, 255, 0.819);
  backdrop-filter: blur(50px);

  -webkit-backdrop-filter: blur(50px);
}

.dropdown-blured > li > a:hover {
  background-color: var(--primary-color) !important;
  color: rgb(255, 255, 255) !important;
}

.dropdown-blured > li > a {
  color: var(--primary-color) !important;
}
.svg-bg {
  background-image: url("/public/lines.svg");
  background-repeat: no-repeat;
  background-size: cover;
  height: fit-content;
  background-color: var(--primary-color);
  /* z-index: -2; */
  position: relative;
}
.svg-gradient-bg {
  background: linear-gradient(to bottom, rgb(24, 12, 12), var(--primary-color));
  height: fit-content;
  background-size: cover;
  position: relative;
  background-repeat: no-repeat;
  z-index: -2;
}

.svg-gradient-bg::before {
  content: "";
  background-image: url("/public/lines.svg");
  background-repeat: repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
}

.black-p {
  color: black !important;
  font-size: 19px;
}
.black-p > b {
  color: black !important;
  font-size: 19px;
}
.center-div {
  margin-top: -130px;
  border-radius: 60px;
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
  padding: 40px;
  width: 85%;
}
#about {
  margin-top: 100px !important;
}
.about-us-img {
  width: 100%;
  margin-left: -14px;
  margin-bottom: -14px;
}
.about-us-img-wrapper {
  /* border: 2px dashed #c024cc; */
  border-radius: 50px;
  border-right: none;
  overflow: hidden;
  /* margin-top: 35px; */
}

.dashed-border {
  position: relative;
  border: 2px dashed #c024cc;
  border-radius: 50px;
  border-right: none;
  margin-top: 35px;
  height: 100%;
  z-index: 999;
}
.dashed-border > a {
  text-decoration: none !important;
}

.dashed-border::before,
.dashed-border::after {
  content: "";
  position: absolute;
  right: -7px;
  width: 15px;
  height: 15px;
  background-color: #c024cc;
  border-radius: 50%;
}

.dashed-border::before {
  top: 30px;
}

.dashed-border::after {
  bottom: 30px;
}
.border-left-dot {
  position: relative;
  border: 2px dashed #c024cc;
  border-top-left-radius: 20px;
  border-right: none;
  /* margin-top: 35px; */
  border-bottom: none;
  height: 100%;
}

.border-left-dot::before,
.border-left-dot::after {
  content: "";
  position: absolute;
  right: -7px;
  width: 15px;
  height: 15px;
  background-color: #c024cc;
  border-radius: 50%;
}

.border-left-dot::before {
  top: -8px;
}

.border-left-dot::after {
  bottom: -8px;
  left: -8px;
}
.border-left-only-dot {
  position: relative;
  border: 2px dashed #c024cc;

  border-right: none;
  border-top: none;
  /* margin-top: 35px; */
  border-bottom: none;
  height: 100%;
}

.border-left-only-dot::before,
.border-left-only-dot::after {
  content: "";
  position: absolute;
  left: -7px;
  width: 15px;
  height: 15px;
  background-color: #c024cc;
  border-radius: 50%;
}

.border-left-only-dot::before {
  top: -8px;
}

.border-left-only-dot::after {
  bottom: -8px;
  left: -8px;
}

.heading-line-left {
  position: relative;
  padding-left: 14px;
}

.heading-line-left::before {
  content: "";
  position: absolute;
  left: -25px;
  top: 50%;
  height: 2px;
  width: 25px;
  background-color: #c024cc;
  border-top: 2px dashed #c024cc;
}

.heading-line-left::after {
  content: "";
  position: absolute;
  left: 0px;
  top: 50%;
  width: 10px;
  height: 10px;
  background-color: #c024cc;
  border-radius: 50%;
  margin-top: -4px;
}

.bordered-div {
  background-color: #4d459b;
  height: 100%;
  border-radius: 50px;
  z-index: -1;
  position: relative;
  margin-right: -50px;
}
.invert-shadow-div {
  background-color: var(--primary-color);
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.4);
  height: 100%;
  border-radius: 50px;
}
.join-us-line {
  background-color: #4d459b;
  height: 100%;
  border-radius: 50px;
  z-index: -1;
  position: relative;
  margin-right: -50px;
}
.join-us-img {
  width: 70%;
}
.btn-talk {
  background: linear-gradient(to left, #443b94, #23164b);
}
.btn-talk:hover {
  background: white !important;
  border: 1px solid var(--primary-color) !important;
  color: var(--primary-color) !important;
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
}
.join-us-line {
  padding: 2.2px;
  z-index: 2;
  border-radius: 100%;
  background-color: var(--primary-color);
}

.testimonial-slider-container {
  position: relative;
}

.testimonial-slide {
  text-align: left;
  padding: 20px;
  font-size: 1.2em;
}

.testimonial-quote {
  font-size: 25em;
  line-height: 0.6;
}

.testimonial-client-info {
  font-size: 0.9em;
}

.slider-arrows {
  display: flex;
  justify-content: end;
  align-items: center;
  margin-top: 10px;
}

.arrow {
  background: transparent;
  border: none;
  outline: none;
  font-size: 20px;
  color: var(--primary-color) !important;
  padding: 0 10px;
  cursor: pointer;
}

/* Adjust the styles for the dots if needed */
.slick-dots button {
  font-size: 22px;
  color: var(--primary-color) !important;
  margin: 0 5px;
}

.slick-dots .slick-active button {
  color: var(--primary-color) !important;
}

.contact-us-container {
  background-image: url("/public/Images/footer.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.overlay {
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

.contact-us-content {
  position: relative;
  z-index: 2;
}

.contact-input {
  background-color: var(--primary-color) !important;
  box-shadow: inset 0 0 15px rgba(0, 0, 0, 0.4);
  color: white !important;
  border: 1px solid var(--primary-color);
}

.gradient-overlay {
  position: relative;
  border-radius: 50px;
}

.gradient-overlay::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  border-radius: 50px;
  left: 0;
  background-image: linear-gradient(
    to bottom left,
    rgba(255, 255, 255, 0.88) 1%,
    rgba(25, 4, 86, 0.218) 99%
  );
  pointer-events: none;
}

@media (max-width: 786px) {
  .res-img {
    width: 100% !important;
    height: auto !important;
  }
}

.market-analysis {
  position: relative; /* Add this line to ensure the overlay is positioned relative to this element */
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url("/public/Images/market-analysis.jpg");
  min-height: 100% !important;
  object-fit: cover;
  border-radius: 80px !important;
  box-shadow: 2px 2px 2px 3px rgba(0, 0, 0, 0.503);
}

.market-analysis::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #38247459; /* hange the last value (0.5) to set the desired overlay opacity */
  backdrop-filter: blur(7px); /* Set the desired blur value */
  border-radius: 80px; /* Add this line to match the border-radius of the parent */
  z-index: 1; /* Add this line to place the overlay above the content */
}

/* Add this rule to ensure the content inside the .market-analysis div is placed above the overlay */
.market-analysis > * {
  position: relative;
  z-index: 2;
}

.market-research {
  margin-top: 140px;
}
.compititor-analysis {
  margin-top: -80px;
}
.customer-satisfiaction {
  margin-top: 40px;
}

.eleminate-problem {
  position: relative; /* Add this line to ensure the overlay is positioned relative to this element */
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url("/public/Images/1.png");
  min-height: 100% !important;
  object-fit: cover;
}

.eleminate-problem::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #38247459; /* hange the last value (0.5) to set the desired overlay opacity */
  backdrop-filter: blur(10px); /* Set the desired blur value */
  z-index: 1; /* Add this line to place the overlay above the content */
}

.eleminate-problem > * {
  position: relative;
  z-index: 2;
}
.digital-marketing-div {
  background: linear-gradient(to left, #665fa7be, #381370d4) !important;
  box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.6);
}
.contact-us-div {
  background-color: #4e459b65 !important;
  border: 1px solid var(--primary-color) !important;
  border-radius: 45px !important ;
}
.btn-contact-us {
  background-color: var(--primary-color);
}
.overlay-div-marketing {
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background: linear-gradient(to right, #4d459b, #372178) !important;
  width: 100%;
  min-height: auto;
  border-radius: 20px !important;
  box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.9);
  border: 1px solid #c124ccaf !important;
  z-index: 10;
  margin-left: -100px;
}
.overlay-div-seo {
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background: linear-gradient(to right, #4d459b, #372178) !important;
  width: 100%;
  min-height: auto;
  border-radius: 20px !important;
  box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.9);
  border: 1px solid #c124ccaf !important;
  z-index: 10;
}
.seo-div-wrapper {
  margin-right: -100px;
}
.bottom-over-lay-div {
  margin-left: 0px !important;
  height: 100% !important;
  margin-bottom: -120px !important;
}
.seo-div {
  background-color: #39146cbc !important;
}
.seo-contact-btn {
  border: 1px solid #c124ccc4 !important;
}
.heading-marketing {
  z-index: 99999 !important;
}
.marketing-bg-img {
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100%;
  min-height: auto;
  border-radius: 40px !important;
  box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.5);
}

.marketing-bg-img::before {
  border-radius: 40px !important;
  content: "";
  background: linear-gradient(
    to left,
    #665fa7,
    30%,
    #170334
  ) !important; /* Change the color and opacity as needed */
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.7;
  z-index: 1;
}
.seo-bg-img {
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100%;
  min-height: auto;
  border-radius: 40px !important;
  box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.5);
}

.seo-bg-img::before {
  border-radius: 40px !important;
  content: "";
  background: linear-gradient(
    to top,
    #665fa7,
    30%,
    #170334
  ) !important; /* Change the color and opacity as needed */
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.7;
  z-index: 1;
}
@media only screen and (max-width: 786px) {
  .bottom-over-lay-div {
    margin-bottom: 18px !important;
  }
  .design-btns{
    width: 90% !important;

  }
  .design-heading-res{
    font-size: 2.3rem !important;
  }
  .seo-div-wrapper {
    margin-right: 0px !important;
    margin-left: 0px !important;
  }
  .overlay-div-marketing {
    margin-left: 0px !important;
  }
  .markiting-row {
    display: flex !important;
    justify-content: center !important;
  }
}
.scroll-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
}

.scroll-to-top__button {
  background-color: #000;
  color: #fff;
  font-size: 24px;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  cursor: pointer;
  opacity: 0.7;
  transition: opacity 0.3s;
  border: 1px solid rgba(255, 255, 255, 0.673);
}

.scroll-to-top__button:hover {
  opacity: 1;
}
.zoom-div {
  transition: transform 0.4s ease;
}

.zoom-div:hover {
  transform: scale(1.05);
  box-shadow: 0px 0px 10px 2px rgba(255, 255, 255, 0.5);
}
.glow-div {
  transition: transform 0.4s ease;
}

.glow-div:hover {
  box-shadow: 0px 0px 10px 2px rgba(255, 255, 255, 0.5);
}

.zoom-hover {
  transform: scale(1.05);
  box-shadow: 0px 0px 10px 2px rgba(255, 255, 255, 0.5);
}
.cookie-banner {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--primary-color);
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 999999999;
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
  
}

.cookie-banner p {
  margin: 0;
  padding-right: 1rem;
}


