@import url(https://fonts.googleapis.com/css2?family=Inter&display=swap);
:root {
  --size: max(5px, 1vmin);
  --heght: 100%;
  --pad: 1.25em;
  --border-radius: 6.666em;
  --gutter: calc(var(--pad) * 2);
  --button-width: 0.333em;
  --notch-height: 3.33em;
  --notch-width: 33.3%;
  --notch-radius: calc(var(--border-radius) - calc(var(--pad) * 2));
  --notch-duration: 0.333s;
  --ease: cubic-bezier(0.666, 0, 0.4, 1);
  --ease-spring: cubic-bezier(0.666, 0, 0.4, 1.2);
  --ease-out: cubic-bezier(0.15, 0, 0.333, 1);
  --border-width: 0.4em;
  --deep-purple: 284;
  --gold: 30;
  --space-black: 215;
  --silver: 254;
  --c-h: var(--deep-purple);
  --c-s: 100%;
  --c-l: 50%;
  --primary-color: #382474;
}
@-webkit-keyframes appear {
  to {
    transform: scale3d(1, 1, 1);
    opacity: 1;
  }
}
@keyframes appear {
  to {
    transform: scale3d(1, 1, 1);
    opacity: 1;
  }
}
.phone {
  position: relative;
  z-index: 1;
  aspect-ratio: 37/61;
  background: #000;
  height: fit-content !important;
  border-radius: var(--border-radius);
  box-shadow: 0 0 0.1em 0.25em hsl(var(--c-h), 20%, 25%),
    0 0 0 var(--border-width) hsl(var(--c-h), 30%, 85%);
  box-sizing: border-box;
  opacity: 0;
  transform: scale3d(1.1, 1.1, 1);
  -webkit-animation: appear 1s var(--ease-out) forwards;
  animation: appear 1s var(--ease-out) forwards;
  -webkit-backface-visibility: hidden;
}
.phone::before {
  content: "";
  position: absolute;
  top: var(--border-radius);
  right: calc(var(--border-width) * -1);
  bottom: calc(var(--border-radius));
  left: calc(var(--border-width) * -1);
  border: 0.5em solid hsl(var(--c-h), 20%, 30%);
  border-left-width: 0;
  border-right-width: 0;
}
.buttons {
  position: absolute;
  inset: calc(var(--border-width) * -1);
  pointer-events: none;
}
.buttons .left,
.buttons .right {
  position: absolute;
  width: var(--button-width);
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 1.5em;
}
.buttons .left {
  right: 100%;
  top: calc(var(--border-radius) * 2);
}
.buttons .left .button:nth-child(1) {
  height: 3em;
  margin-bottom: 0.5em;
}
.buttons .right {
  left: 100%;
  transform: scale3d(-1, 1, 1);
  top: calc(var(--border-radius) * 3);
}
.buttons .right .button {
  height: 9.5em;
}
.buttons .button {
  background: hsl(var(--c-h), 20%, 95%);
  height: 6em;
  box-shadow: inset -0.15em 0 0.1em #000,
    inset 0 0 0.1em hsl(var(--c-h), 30%, 90%),
    inset 0 0.2em 0.1em hsl(var(--c-h), 30%, 90%),
    inset 0 -0.2em 0.1em hsl(var(--c-h), 30%, 90%),
    inset -0.1em 0.333em 0.1em rgba(0, 0, 0, 0.5),
    inset -0.1em -0.333em 0.1em rgba(0, 0, 0, 0.5);
  border-top-left-radius: 0.2em;
  border-bottom-left-radius: 0.2em;
}
.screen-container {
  position: absolute;
  inset: 0;
  border-radius: var(--border-radius);
  border: var(--pad) solid #000;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: calc(var(--pad) * 2);
}
/* .screen-container::before {
  content: "";
  position: absolute;
  z-index: 2;
  background: #fff;
  width: 36.6%;
  bottom: calc(var(--pad) * 0.75);
  height: calc(var(--pad) * 0.5);
  border-radius: calc(var(--pad) * 0.25);
  filter: drop-shadow(0 0.1em 0.25em rgba(0, 0, 0, 0.1));
} */
.bg {
  position: absolute;
  inset: 0;
  background: linear-gradient(to bottom, var(--primary-color), rgb(24, 12, 12));
  border-radius: calc(var(--border-radius) - var(--pad));
  overflow: hidden;
  padding: 50px;
  padding-top: 120px;
}
.mobile-content-div {
  background: linear-gradient(to left, #8c1695b6, var(--primary-color));
  border-radius: 30px;
  width: fit-content;
  padding-right: 100px !important;
  font-size: 30px;
}
.mobile-content-div-lg-1{
    background:  var(--primary-color);
    border-radius: 30px;
    border: 1px solid #af2cb98c;

}
.mobile-content-div-lg-2{
    background:  #5b40ad;;
    border-radius: 30px;
    border: 1px solid var(--primary-color);

}
.mobile-screen{
    border-left: 2px solid #af2cb9;
    border-right: 2px solid #af2cb9;
}
@media only screen and (max-width: 786px) {
    .mobile-content-div {
        border-radius: 20px;
        font-size: 20px;
        margin-left: 0px !important;
        padding-right: 25px !important;


      }
      .mobile-screen{
        padding: 0px !important;
      }

}
@media only screen and (max-width: 550px) {
    .mobile-content-div {
        border-radius: 20px;
        font-size: 13px;
        margin-left: 0px !important;
        padding-right: 25px !important;


      }

}
.mobile-content-div::before {
    content: "";
    position: absolute;
    width: 4px; /* Width of the line */
    height: 100%; /* Height of the line */
    background-color: black; /* Color of the line */
    left: 50%; /* Position the line at the center of the div */
    top: 0; /* Position the line at the top of the div */
    z-index: -1; /* Set the z-index to -1 to place the line behind the div */
  }

.bg > * {
  /* position: absolute; */
  inset: 0;
  display: flex;
  flex-direction: column;
  transition: opacity 1s var(--ease-out) 0.5s;
}
.bg .section {
  --g-h: var(--c-h);
  --g-s: var(--c-s);
  --g-l: var(--c-l);
  flex-grow: 1;
  position: relative;
  overflow: hidden;
  border-radius: calc(var(--border-radius) - var(--pad));
  border-bottom-left-radius: 20em;
  border-bottom-right-radius: 20em;
}
.bg .section:before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: inherit;
  background: radial-gradient(85% 125% at 50% 140%, #000 66.6%, transparent),
    radial-gradient(
      120% 120% at 50% 145%,
      hsl(calc(var(--g-h) - 45), 100%, 50%) 50%,
      transparent
    ),
    radial-gradient(
      150% 100% at 50% 80%,
      transparent 35%,
      hsl(calc(var(--g-h) + 50), 100%, 99%)
    );
  background-color: hsl(var(--g-h), var(--g-s), var(--g-l));
  transform: scale3d(1.1, 1.25, 1);
  transform-origin: bottom;
  transition: transform 1s var(--ease-out) 0.5s;
}
.bg .section:after {
  content: "";
  position: absolute;
  inset: 0;
  border: var(--border-width) solid rgba(255, 255, 255, 0.8);
  border-radius: inherit;
  filter: blur(0.05em);
  -webkit-mask-image: radial-gradient(
    100% 100% at 50% 70%,
    #000 30%,
    transparent 50%
  );
  mask-image: radial-gradient(100% 100% at 50% 70%, #000 30%, transparent 50%);
}
.bg .section .glow {
  position: absolute;
  inset: 0;
  border-radius: inherit;
  mix-blend-mode: overlay;
  z-index: 1;
  background: radial-gradient(
    80% 150% at 50% 100%,
    hsl(var(--g-h), 100%, var(--g-l)),
    transparent 70%
  );
}
.bg .section:last-of-type {
  --g-h: calc(var(--c-h) - var(--g-hue-adjust, 0));
  --g-s: calc(var(--c-s) - 25%);
  --g-l: calc(var(--c-l) + 20%);
  transform: scale3d(1, -1, 1) translateZ(1px);
}
.bg .section:last-of-type::before {
  background: radial-gradient(85% 125% at 50% 140%, #000 66.6%, transparent),
    radial-gradient(
      120% 120% at 50% 145%,
      hsl(
          calc(var(--g-h) - var(--g-hue-adjust-2, var(--g-hue-adjust, 0))),
          100%,
          50%
        )
        50%,
      transparent
    ),
    radial-gradient(
      150% 100% at 50% 95%,
      transparent 15%,
      hsl(calc(var(--g-h) + 5), 100%, var(--g-lightness, 100%))
    );
  background-color: hsl(var(--g-h), var(--g-s), var(--g-l));
}
.notch-container {
  position: absolute;
  z-index: 3;
  top: var(--pad);
  right: var(--pad);
  left: var(--pad);
  display: flex;
  justify-content: center;
  height: 100%;
  max-height: calc(var(--notch-radius) * 2);
  pointer-events: none;
  outline: 0;
  transition: var(--notch-duration) var(--ease);
  transition-property: max-height, max-width, filter, transform;
  will-change: max-width, max-height, filter;
}
.notch-container:focus-within,
.notch-container:hover {
  --shadow-opacity: 0.5s;
  transition-timing-function: var(--ease-spring);
}
.notch-container:focus-within .content,
.notch-container:hover .content {
  --content-padding: 2em;
}
.notch-container:focus-within .content .text,
.notch-container:hover .content .text {
  opacity: 1;
}

.notch-blur {
  position: absolute;
  z-index: 2;
  top: calc(var(--pad) - 3px);
  right: calc(var(--pad) - 3px);
  left: calc(var(--pad) - 3px);
  height: 100%;
  max-height: calc(var(--notch-radius) * 1.5);
  -webkit-backdrop-filter: blur(0.2em);
  backdrop-filter: blur(0.2em);
  fill: blur(0.1em);
  -webkit-mask-image: linear-gradient(
    to bottom,
    #000 calc(100% - var(--notch-radius)),
    transparent
  );
  mask-image: linear-gradient(
    to bottom,
    #000 calc(100% - var(--notch-radius)),
    transparent
  );
  opacity: 0;
  transition: var(--notch-duration) ease-in-out;
  border-radius: calc(var(--border-radius) - var(--pad));
  transition: var(--notch-duration) var(--ease);
  transition-property: max-height, max-width, filter, transform;
  will-change: max-width, max-height, filter;
}
.notch {
  position: relative;
  background: #000;
  border-radius: var(--notch-radius);
  pointer-events: all;
  overflow: hidden;
  color: #fff;
  display: flex;
  cursor: pointer;
  width: 100%;
  transition: inherit;
  transition-property: inherit;
  will-change: inherit;
  filter: drop-shadow(0 1em 2em hsla(0 0 0 / var(--shadow-opacity, 0)));
  transform: scale3d(0.375, 0.4, 1);
  transform-origin: top;
}

.camera {
  display: flex;
  justify-content: center;
  align-items: center;
  height: var(--notch-height);
  aspect-ratio: 1/1;
  border-radius: 50%;
  pointer-events: none;
  position: absolute;
  z-index: 4;
  top: calc(var(--pad) * 2);
  right: calc(50% - calc(var(--notch-width) * 0.5));
  margin-right: calc(var(--pad) * 0.333);
}
.camera::before {
  content: "";
  height: 33.3%;
  aspect-ratio: 1;
  border-radius: inherit;
  box-shadow: inset 0 0 0.25em #4c4da3;
  background: radial-gradient(#6667ac, transparent 50%) no-repeat 33.3% 10%/75%
      50%,
    radial-gradient(#454680, transparent 50%) no-repeat 60% 85%/50% 50%;
  background-color: #080928;
}
.deep-purple {
  --c-h: var(--deep-purple);
  --g-hue-adjust: 60;
}
.gold {
  --c-h: var(--gold);
  --g-hue-adjust-2: -100;
}
.space-black {
  --c-h: var(--space-black);
  --g-hue-adjust: 190;
  --g-hue-adjust-2: 145;
  --g-lightness: 95%;
}
.silver {
  --c-h: var(--silver);
  --c-s: 10%;
  --g-hue-adjust: 30;
  --g-lightness: 95%;
}
.silver .glow {
  opacity: 0.25;
}
#deep-purple:checked ~ .phone .bg .deep-purple {
  opacity: 1;
  z-index: 1;
  transition-delay: 0s;
}
#deep-purple:checked ~ .phone .bg .deep-purple .section::before {
  transition-delay: 0s;
  animation: test 1s infinite alternate-reverse;
}
#gold:checked ~ .phone .bg .gold {
  opacity: 1;
  z-index: 1;
  transition-delay: 0s;
}
#gold:checked ~ .phone .bg .gold .section::before {
  transition-delay: 0s;
  transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  animation: test 1s infinite alternate-reverse;
}
#space-black:checked ~ .phone .bg .space-black {
  opacity: 1;
  z-index: 1;
  transition-delay: 0s;
}
#space-black:checked ~ .phone .bg .space-black .section::before {
  transition-delay: 0s;
  transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  animation: test 1s infinite alternate-reverse;
}
#silver:checked ~ .phone .bg .silver {
  opacity: 1;
  z-index: 1;
  transition-delay: 0s;
}
#silver:checked ~ .phone .bg .silver .section::before {
  transition-delay: 0s;
  transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  animation: test 1s infinite alternate-reverse;
}
@keyframes test {
  from {
    transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  }
  to {
    transform: scale3d(2, 2, 2) translate3d(50px, 50px, 50px);
  }
}
